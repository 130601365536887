<template>
  <v-chip class="df-chip" :color="backgroundColor">
    <span :class="type"><slot></slot></span>
    <font-awesome-icon v-if="icon" :icon="icon" />
  </v-chip>
</template>

<script>
export default {
  name: 'DfChip',

  props: {
    icon: {
      default: '',
      type: String,
    },
    type: {
      default: '',
      type: String,
      validator: (value) =>
        ['success', 'warning', 'error', 'primary', 'secondary'].includes(value),
    },
  },

  computed: {
    backgroundColor() {
      const colors = {
        success: '#D8F2DC',
        warning: '#F2CD12',
        error: '#FFE1DF',
        primary: '#39af49',
        secondary: '#1A2B46',
      }
      return colors[this.type] || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.df-chip {
  border-radius: 4px !important;
  padding: 2px 4px;
  height: fit-content !important;
  max-height: fit-content;
  width: fit-content !important;
  max-width: fit-content;

  span {
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    .success {
      color: #39af49;
      background-color: #d8f2dc !important;
    }
    .warning {
      color: #fff;
      background-color: #f2cd12 !important;
    }
    .error {
      color: #f23528;
      background-color: #ffe1df !important;
    }
    .primary {
      color: #ffffff;
      background-color: #39af49 !important;
    }
    .secondary {
      color: #ffffff;
      background-color: #1a2b46 !important;
    }
  }
}
</style>
